export enum StudentActionTypes {
  API_RESPONSE_SUCCESS = "@@students/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@students/API_RESPONSE_ERROR",
  CREATE_STUDENT = "@@students/CREATE_STUDENT",
  EDIT_STUDENT = "@@students/EDIT_STUDENT",
  APPROVE_STUDENT = "@@students/APPROVE_STUDENT",
  DELETE_STUDENT = "@@students/DELETE_STUDENT",
  PERMANENT_DELETE_STUDENT = "@@students/PERMANENT_DELETE_STUDENT",
  GET_STUDENT = "@@students/GET_STUDENT",
  GET_DELETED_STUDENT = "@@students/GET_DELETED_STUDENT",
  GET_STUDENT_BY_STAFF = "@@students/GET_STUDENT_BY_STAFF",
  GET_STUDENT_BY_CREATED = "@@students/GET_STUDENT_BY_CREATED",
  GET_ASSIGNED_STUDENT = "@@students/GET_ASSIGNED_STUDENT",
  GET_STUDENT_BY_ID = "@@students/GET_STUDENT_BY_ID",
  GET_STUDENT_BY_CONSULTANT = "@@students/GET_STUDENT_BY_CONSULTANT",
}
