export enum AdminActionTypes {
  API_RESPONSE_SUCCESS = "@@cred_admin/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@cred_admin/API_RESPONSE_ERROR",
  CREATE_CRED_ADMIN_USER = "@@cred_admin/CREATE_CRED_ADMIN_USER",
  EDIT_CRED_ADMIN_USER = "@@cred_admin/EDIT_CRED_ADMIN_USER",
  GET_CRED_ADMIN_USERS = "@@cred_admin/GET_CRED_ADMIN_USERS",
  GET_CRED_ADMIN_USERS_BY_ID = "@@cred_admin/GET_CRED_ADMIN_USERS_BY_ID",
  DELETE_CRED_ADMIN_USERS = "@@cred_admin/DELETE_CRED_ADMIN_USERS",
  RESET_ADMIN_PASSWORD = "@@cred_admin/RESET_ADMIN_PASSWORD",
}
